import { default as indexdc6c2he1UCMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20241007083935703/pages/about/index.vue?macro=true";
import { default as index0eCkhllgvXMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20241007083935703/pages/contact/index.vue?macro=true";
import { default as indexuQCnfYNerwMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20241007083935703/pages/index.vue?macro=true";
import { default as _91slug_93eBlhQ3IzZVMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20241007083935703/pages/league/[slug].vue?macro=true";
import { default as _91slug_93fB1QcIXVdzMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20241007083935703/pages/match/[slug].vue?macro=true";
import { default as indexhFIGPVPZwsMeta } from "/home/cleavr/futbolhoyenvivo.mx/releases/20241007083935703/pages/privacy-policy/index.vue?macro=true";
export default [
  {
    name: "about___es-mx",
    path: "/sobre-nosotros",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20241007083935703/pages/about/index.vue")
  },
  {
    name: "contact___es-mx",
    path: "/contacto",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20241007083935703/pages/contact/index.vue")
  },
  {
    name: "index___es-mx",
    path: "/",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20241007083935703/pages/index.vue")
  },
  {
    name: "league-slug___es-mx",
    path: "/liga/:slug()",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20241007083935703/pages/league/[slug].vue")
  },
  {
    name: "match-slug___es-mx",
    path: "/noticias/:slug()",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20241007083935703/pages/match/[slug].vue")
  },
  {
    name: "privacy-policy___es-mx",
    path: "/politica-de-privacidad",
    component: () => import("/home/cleavr/futbolhoyenvivo.mx/releases/20241007083935703/pages/privacy-policy/index.vue")
  }
]